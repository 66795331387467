import en from './en';

import {
    AssetDoors,
    AssetEuroNorm,
    AssetFuel,
    AssetGearboxType,
    AssetSeatUpholstery,
    AssetType,
    AssetWheelchairRamp,
    AuctionState,
    BackendErrorCode,
    BusClass,
    CruiseControlType,
    DeckType,
    GasTanksMaterials,
    Glasses,
    RequireScrappingFilter,
    UserType,
    VehicleCondition,
} from '~/apiClient';

type i18nKeys = keyof typeof en;
type i18nValues = (typeof en)[i18nKeys];

const it: Record<i18nKeys, i18nValues> = {
    account: {
        bids: {
            title: 'Le tue offerte',
            emptyState: {
                base: "Non hai ancora piazzato un'offerta.",
                cta: 'Partecipa alle aste',
            },
            filters: {
                bidPosition: {
                    base: 'Aggiudicazione',
                    anyOption: 'Tutte le offerte',
                    maxBidder: 'La tua offerta è la migliore',
                    outbid: 'Offerta superata',
                },
                state: {
                    base: "Stato dell'asta",
                    anyOption: 'Tutte le aste',
                    [AuctionState.Closed]: 'Aste concluse',
                    [AuctionState.Running]: 'Aste in corso',
                },
            },
        },
        sales: {
            title: 'Le tue vendite',
            emptyStateText: 'Non risultano aste con venditore associato a questo account.',
        },
    },
    common: {
        logout: 'Esci',
        login: 'Accedi',
        loginSignup: 'Accedi/Registrati',
        loginTitle: 'Accedi a Fleequid',
        profile: 'Il tuo profilo',
        searchPlaceholder: 'Cerca...',
        searchNoResults: 'Nessun risultato trovato per"{query}"',
        email: 'Email',
        yourAccount: 'Il tuo account',
    },
    cookie: {
        name: 'Nome',
        provider: 'Fornitore',
        purpose: 'Scopo',
        expiry: 'Scadenza',
        type: 'Tipo',
    },
    error: {
        backend: {
            [BackendErrorCode.InvalidEnumValue]: 'Tipo di dato invalido',
            [BackendErrorCode.InvalidDate]: 'Formato data invalido',
            [BackendErrorCode.InvalidLength]: 'Lunghezza errata. Assicurati di aver inserito solo i caratteri necessari',
            [BackendErrorCode.NotAString]: 'Tipo invalido; inserisci un testo',
            [BackendErrorCode.EmptyString]: 'Dato mancante. Completa tutti le informazioni obbligatorie',
            [BackendErrorCode.PrismaError]: 'Impossibile salvare il dato; prova un valore diverso',
            [BackendErrorCode.AuthAuthenticationVersionMismatch]:
                'La tua sessione è scaduta. Ti invitiamo a effettuare nuovamente il login per continuare',
            [BackendErrorCode.AuthWrongCredentials]: 'Credenziali non valide',
            [BackendErrorCode.AuthNoUserByEmail]: "Non esiste nessun account associato all'indirizzo email inserito.",
            [BackendErrorCode.AuthWrongPassword]: 'La password inserita non è corretta.',
            [BackendErrorCode.UserIsBanned]: 'La tua utenza è stata sospesa. Contattaci per ulteriori informazioni.',
            [BackendErrorCode.AuthEmailNotVerified]:
                "L'indirizzo email inserito non risulta ancora verificato. Abbiamo inviato un nuovo link per la verifica dell'email.",
            [BackendErrorCode.SignupUserAlreadyExists]: 'Indirizzo email già registrato, se hai dimenticato la password prova a recuperarla.',
            [BackendErrorCode.UploadFileError]: 'Errore durante il caricamento del file',
            [BackendErrorCode.IsNotTrue]: "Selezione mancante. Spunta l'opzione obbligatoria",
            [BackendErrorCode.EntityNotFound]: 'Nessun risultato trovato',
            [BackendErrorCode.PasswordResetEmailNotExists]: 'Non esiste nessun account associato a questa email',
            [BackendErrorCode.EmailChangeWrongPassword]: "Non puoi modificare l'indirizzo email perché la password non è corretta",
            [BackendErrorCode.CantBidUserCompanyEqSellerCompany]: 'Questo asset è di proprietà della tua azienda, non puoi acquistarlo.',
            authentication: 'Errore di autenticazione; riprova il login',
        },
        '404': {
            title: '404',
            subtitle: 'Ops! Pagina non trovata',
            description: 'Siamo spiacenti, la pagina richiesta non esiste. Ad ogni modo troverai molto altro da esplorare su Fleequid.',
            button: 'Torna alla homepage',
        },
        '500': {
            title: '500',
            subtitle: 'Ops! Si è verificato un errore',
            description: "Ci spiace per l'inconveniente. Stiamo già lavorando per risolvere il problema.",
            help: {
                base: 'Nel frattempo puoi riprovare più tardi o {contactUs}',
                contactUs: 'contattarci a {email}, oppure {phone} per emergenze',
            },
            button: 'Torna alla homepage',
        },
        default: {
            title: 'Errore',
            subtitle: 'Si è verificato un errore imprevisto',
            description: 'Stiamo già lavorando per risolvere il problema.',
            button: 'Torna alla homepage',
        },
    },
    a11y: {
        auctionDetail: 'Scopri di più su {name}',
    },
    adp: {
        imageGallery: 'Galleria completa ({totalImages})',
        videoGallery: 'Guarda video ({totalVideos})',
        imagePagination: 'Foto {0} di {1}',
        videoPagination: 'Video {0} di {1}',
        papers: 'Documenti',
        registrationDocument: 'Libretto di circolazione',
        cocDocument: 'C.O.C.',
        maintenanceCard: 'Storico manutenzioni',
        detailsTitle: 'Dettagli del veicolo',
    },
    asset: {
        type: {
            [AssetType.Bus]: 'Autobus',
        },
        class: {
            base: 'Classe',
            [BusClass.ClassOne]: 'Urbano',
            [BusClass.ClassTwo]: 'Interurbano',
            [BusClass.ClassThree]: 'Turistico',
            [BusClass.ClassA]: 'Classe A',
            [BusClass.ClassB]: 'Classe B',
            [BusClass.SchoolBus]: 'Scuolabus',
        },
        kilometers: 'Chilometraggio',
        firstRegistration: 'Immatricolazione',
        antiPollution: 'Normativa antinquinamento',
        euroNorm: {
            [AssetEuroNorm.Euro0]: 'Euro 0',
            [AssetEuroNorm.Euro1]: 'Euro 1',
            [AssetEuroNorm.Euro2]: 'Euro 2',
            [AssetEuroNorm.Euro3]: 'Euro 3',
            [AssetEuroNorm.Euro4]: 'Euro 4',
            [AssetEuroNorm.Euro5]: 'Euro 5',
            [AssetEuroNorm.Euro6]: 'Euro 6',
        },
        toBeScraped: 'Da demolire',
        expiredInspection: 'Revisione scaduta',
        additionalNotes: 'Specifiche del veicolo',
        description: 'Descrizione',
        version: 'Versione',
        geographicalLocation: 'Località',
        vehicleCondition: {
            base: 'Condizione del veicolo',
            [VehicleCondition.Blocked]: 'Non marciante',
            [VehicleCondition.Rollable]: 'Trainabile',
            [VehicleCondition.Running]: 'Marciante',
        },
        length: 'Lunghezza',
        height: 'Altezza',
        width: 'Larghezza',
        vin: 'Telaio',
        converter: 'Allestitore',
        visitDate: {
            base: 'Visionabile',
            fromTo: 'il {date} dalle {fromTime} alle {toTime}',
        },
        inspectionExpiryDate: 'Data scadenza revisione',
        doors: {
            base: 'Porte',
            [AssetDoors.Double]: '2',
            [AssetDoors.DoubleDouble]: '2+2',
            [AssetDoors.DoubleDoubleDouble]: '2+2+2',
            [AssetDoors.DoubleDoubleSingle]: '2+2+1',
            [AssetDoors.DoubleSingleSingle]: '2+1+1',
            [AssetDoors.Single]: '1',
            [AssetDoors.SingleDouble]: '1+2',
            [AssetDoors.SingleSingle]: '1+1',
            [AssetDoors.SingleDoubleSingle]: '1+2+1',
            [AssetDoors.SingleSingleSingle]: '1+1+1',
            [AssetDoors.SingleDoubleDouble]: '1+2+2',
        },
        seatUpholstery: {
            base: 'Rivestimento sedili',
            [AssetSeatUpholstery.Fabric]: 'Tessuto',
            [AssetSeatUpholstery.Leather]: 'Pelle',
            [AssetSeatUpholstery.Plastic]: 'Plastica',
            [AssetSeatUpholstery.FabricLeather]: 'Pelle/tessuto',
        },
        totalCapacity: 'Numero totale posti',
        seats: 'Posti a sedere',
        seatsFromS1: 'Posti a sedere da libretto conducente compreso (S1)',
        standingPlaces: 'Posti in piedi',
        standingPlacesFromS2: 'Posti in piedi da libretto (S2)',
        wheelchairPlaces: 'Posti carrozzina',
        companionSeats: 'Posti per accompagnatori',
        wheelchairRamp: {
            base: 'Tipologia rampa disabile',
            [AssetWheelchairRamp.Absent]: 'Assente',
            [AssetWheelchairRamp.Electric]: 'Elettrica',
            [AssetWheelchairRamp.Manual]: 'Manuale',
            [AssetWheelchairRamp.Electropneumatic]: 'Elettro-pneumatica',
        },
        luggageCapacity: 'Capacità bagagliera',
        passThroughLuggageCompartment: 'Bagagliera passante',
        hatBox: 'Cappelliera',
        transmission: 'Cambio',
        gearboxType: {
            base: 'Tipologia cambio',
            [AssetGearboxType.Auto]: 'Automatico',
            [AssetGearboxType.Manual]: 'Manuale',
            [AssetGearboxType.SemiAuto]: 'Semiautomatico',
        },
        gearboxBrand: 'Marca cambio',
        gearboxModel: 'Modello cambio',
        glasses: {
            base: 'Vetri',
            [Glasses.Single]: 'Singoli',
            [Glasses.Double]: 'Doppi',
        },
        openableWindows: 'Finestrini apribili',
        engine: 'Motore',
        engineBrand: 'Marca motore',
        engineModel: 'Modello motore',
        hp: 'Cavalli',
        kw: 'Potenza',
        displacement: 'Cilindrata',
        cylinders: 'Numero cilindri',
        fuel: {
            base: 'Alimentazione',
            [AssetFuel.CNG]: 'CNG',
            [AssetFuel.Electric]: 'Elettrico',
            [AssetFuel.GPL]: 'GPL',
            [AssetFuel.Diesel]: 'Diesel',
            [AssetFuel.Gasoline]: 'Benzina',
            [AssetFuel.Hybrid]: 'Ibrido',
            [AssetFuel.Hydrogen]: 'Idrogeno',
        },
        fuelTankCapacity: 'Capacità serbatoio',
        gasTanksCapacity: 'Serbatoi gas',
        gasTanksMaterial: {
            base: 'Materiale serbatoio',
            [GasTanksMaterials.Aluminum]: 'Alluminio',
            [GasTanksMaterials.Composite]: 'Composito',
            [GasTanksMaterials.Steel]: 'Acciaio',
        },
        batteryPower: 'Capacità della batteria',
        chargingType: 'Tipo ricarica',
        axlesTires: 'Assali e pneumatici',
        wheelSize: 'Dimensione pneumatici',
        wheels: 'Numero pneumatici (doppi o singoli)',
        axles: 'Assi',
        wheelsSizeAxle1: 'Dimensione ruote asse 1',
        wheelsSizeAxle2: 'Dimensione ruote asse 2',
        wheelsSizeAxle3: 'Dimensione ruote asse 3',
        alloyWheels: 'Cerchi in lega',
        spareWheel: 'Ruota di scorta',
        firePreventionSystem: 'Sistema antincendio',
        firePreventionSystemBrand: 'Marca sistema antincendio',
        abs: 'ABS',
        asr: 'ASR',
        retarder: 'Rallentatore',
        breathalyzer: 'Etilometro',
        tachograph: 'Tachigrafo',
        indicatorSigns: 'Pannelli indicatori',
        indicatorSignsBrand: 'Produttore pannelli indicatori',
        accessories: 'Accessori',
        cruiseControl: {
            base: 'Cruise control',
            [CruiseControlType.No]: 'No',
            [CruiseControlType.Adaptive]: 'Adaptive',
            [CruiseControlType.Standard]: 'Standard',
        },
        deckType: {
            base: 'Pianale',
            [DeckType.LowEntry]: 'Low Entry',
            [DeckType.LowFloor]: 'Low Floor',
            [DeckType.NormalFloor]: 'Normal Floor',
        },
        acDriver: 'Aria condizionata autista',
        driverBerth: 'Cuccetta conducente',
        wifi: 'Wi-Fi',
        cctv: 'Videosorveglianza (CCTV)',
        internalCameras: 'Telecamere interne',
        externalCameras: 'Telecamere esterne',
        retrocamera: 'Retrocamera',
        microphone: 'Microfono',
        cdPlayer: 'CD player',
        dvdPlayer: 'DVD player',
        usbPlayer: 'Radio USB player',
        heater: 'Riscaldamento',
        preHeater: 'Preriscaldatore',
        kitchen: 'Cucina',
        coffeeMachine: 'Macchina del caffè',
        refrigerator: 'Frigorifero',
        toilet: 'Toilet',
        safetyBelts: 'Cinture di sicurezza',
        acPassengers: 'Aria condizionata passeggero',
        curtains: 'Tendine parasole',
        seatPocket: 'Retina portaoggetti',
        footRest: 'Poggiapiedi',
        handRest: 'Braccioli',
        seatsMonitor: 'Monitor ai sedili',
        seatsRadio: 'Radio ai sedili',
        seatsUsbPorts: 'USB ai sedili',
        socket220: 'Presa di corrente autista 220V',
        seatsSocket220: 'Prese di corrente ai sedili 220V',
        extendableSeats: 'Sedili allargabili',
        recliningSeats: 'Sedili reclinabili',
        coffeeTables: 'Tavolini ai sedili',
    },
    auction: {
        countdown: {
            startsIn: 'Inizia tra',
            endsIn: 'Termina tra',
            days: 'g',
            hours: 'h',
            minutes: 'm',
            seconds: 's',
        },
        bail: {
            title: 'Vincola il deposito cauzionale per partecipare all’asta',
            description: 'Per poter partecipare a quest’asta, dovrai vincolare il deposito cauzionale tramite un metodo di pagamento valido.',
            amount: 'DEPOSITO CAUZIONALE',
            note: "Il deposito cauzionale verrà rilasciato alla fine dell'asta se non risulti tra i migliori offerenti.",
            subnote: "I depositi cauzionali non sono uguali per tutti gli asset e possono variare a seconda della base d'asta",
            error: "Si è verificato un errore durante l'elaborazione della transazione, riprova più tardi o contatta l'assistenza",
            submit: 'Vincola il deposito cauzionale',
        },
        bid: {
            title: 'Diventa subito il miglior offerente',
            description: 'Ricorda che l’offerta è vincolante e qualora la tua offerta non verrà superata, ti impegni al pagamento di questo mezzo.',
            yourNewOffer: 'La tua nuova offerta',
            confirm: 'Conferma subito l’offerta',
            cancel: 'Cancella',
            success: 'Offerta accettata!',
            total: 'Totale',
            outbidError: {
                title: 'Un altro utente ha superato la tua offerta',
                description: 'L’offerta che hai selezionato è già stata superata da un altro utente, chiudi e aggiorna la tua offerta.',
                close: 'Chiudi',
            },
            expiredError: {
                title: "L'asta è terminata",
                description: 'Non è più possibile effettuare altre offerte.',
            },
        },
        bidButton: 'Fai la tua offerta',
        auctionPanel: {
            reservePrice: 'Prezzo di riserva',
            startingPrice: 'Offerta di partenza',
            current: 'Attuale',
            wonAuction: 'Asta aggiudicata!',
            state: {
                [AuctionState.Closed]: 'Asta terminata',
                [AuctionState.Canceled]: 'Asta annullata',
                [AuctionState.Draft]: 'Asta in bozza',
            },
            maxBidder: 'La tua offerta è la migliore!',
            outBid: 'Offerta superata!',
            negotiationInProgress: 'Trattativa in corso',
            feeNotice: '+ commissioni ({0})',
            feeNoticeLink: 'cosa\xa0sono?',
            priceNotAvailable: 'N/D',
            bidNotice: {
                reservePriceNotReached: {
                    base: 'Prezzo di riserva {0}',
                    notReached: 'non ancora raggiunto',
                },
                notScrapyard: "Asta riservata a demolitori iscritti all'albo gestori dei rifiuti in cat. 5 e gestori del codice C.E.R. 16.01.04",
                sameCompany: {
                    [AuctionState.Running]: 'Il veicolo appartiene alla tua azienda',
                    base: 'Il veicolo appartiene alla tua azienda',
                },
                isAdmin: 'Non puoi partecipare perché il tuo utente ha ruolo Admin',
            },
        },
        // SUPPORT
        needSupport: 'Hai bisogno di supporto?',
        contactUs: 'Contatta il servizio clienti Fleequid',
    },
    auctions: {
        page: {
            asset: {
                [AssetType.Bus]: 'autobus',
            },
            title: 'Scopri tutti gli {title} su Fleequid',
            results: '{total} aste visibili',
            emptyStateTitle: 'Nessun risultato',
            emptyStateText: 'I filtri applicati non hanno prodotto nessun risultato.',
            resetFilters: 'Rimuovi filtri',
        },
        filters: {
            applied: 'Filtri applicati',
            button: {
                label: 'Filtra aste',
            },
            modal: {
                title: 'Filtra aste',
                back: 'Torna ai risultati',
            },
            label: 'Filtri',
            class: 'Classe',
            brand: 'Marchio, modello, versione',
            brandFilter: 'Marchio',
            brandFilterAnyOption: 'Tutti i brand',
            modelFilter: 'Modello',
            modelFilterAnyOption: 'Tutti i modelli',
            versionFilter: 'Versione',
            versionFilterAnyOption: 'Tutte le versioni',
            antiPollution: 'Normativa antinquinamento',
            registration: 'Immatricolazione',
            yearFrom: 'A partire da (anno)',
            yearTo: 'Fino a (anno)',
            yearAnyOption: 'Qualsiasi anno',
            kilometers: 'Chilometraggio',
            kmFrom: 'A partire da',
            kmTo: 'Fino a',
            kmAnyOption: 'Qualsiasi chilometraggio',
            state: {
                title: 'Stato asta',
                all: 'Tutti gli stati',
                [AuctionState.Pending]: 'Aste non ancora iniziate',
                [AuctionState.Running]: 'Aste in corso',
                [AuctionState.Closed]: 'Aste terminate',
            },
            scrapping: {
                title: 'Vincoli demolizione',
                help: 'Cosa sono i vincoli di demolizione?',
                all: 'Tutti i veicoli',
                [RequireScrappingFilter.requireScrapping]: 'Con obbligo di demolizione',
                [RequireScrappingFilter.doNotRequireScrapping]: 'Senza obbligo di demolizione',
            },
            fuel: 'Alimentazione',
            length: 'Lunghezza',
            lengthFrom: 'A partire da',
            lengthTo: 'Fino a',
            lengthAnyOption: 'Qualsiasi lunghezza',
            empty: 'Nessun filtro applicato.',
        },
        sorting: {
            title: 'Ordina per',
            yearAsc: 'Immatricolazione meno recente',
            yearDesc: 'Immatricolazione più recente',
            priceAsc: 'Meno costoso',
            priceDesc: 'Più costoso',
            kmAsc: 'Chilometraggio crescente',
            kmDesc: 'Chilometraggio decrescente',
            defaultByState: {
                all: 'Ordine predefinito',
                [AuctionState.Running]: 'Scadenza più imminente',
                [AuctionState.Closed]: 'Termine più recente',
                [AuctionState.Pending]: 'Inizio più imminente',
            },
        },
        appliedFilters: {
            from: 'da: {0}',
            to: 'a: {0}',
            reset: 'Rimuovi filtri',
            appliedFiltersCounter: '{count} filtro attivo | {count} filtri attivi',
        },
    },
    homepage: {
        link: 'Home',
        hero: {
            title: 'Non esistono mezzi pesanti',
            subtitle: 'Partecipa alle nostre aste online e compra o vendi i tuoi mezzi in modo semplice, veloce e trasparente.',
            buttonLabel: 'Partecipa alle aste',
        },
        whatIsFleequid: {
            title: 'Che cos’è Fleequid?',
            description: 'Fleequid è il nuovo modo di vendere e acquistare mezzi all’asta per la tua attività commerciale',
            section: {
                simplicity: {
                    title: 'Semplicità',
                    description: 'Un processo lineare ed intuitivo, che annulla le trattative infinite',
                },
                speed: {
                    title: 'Velocità',
                    description: 'Puoi comprare il tuo nuovo autobus in 7 giorni',
                },
                transparency: {
                    title: 'Trasparenza',
                    description: 'Prezzi in chiaro, immagini dettagliate, supporto dedicato',
                },
            },
        },
        vision: {
            caption: 'La nostra visione',
            title: 'Crediamo nell’usato che conviene. Vogliamo aiutare le imprese a gestire la compravendita di veicoli aziendali risparmiando tempo e risorse, trasformando un problema in un’opportunità.',
            highlight: 'Dimenticati rischi e incertezze: con noi, dare valore ai propri mezzi diventa facile.',
        },
        howItWorks: {
            title: 'Come funziona',
            description: 'Ci impegniamo a mantenere semplice ed intuitiva tutta la nostra piattaforma per permetterti di curare il tuo parco autobus',
            signUp: {
                title: 'Registrati su Fleequid',
                create: 'Crea un {account} usando i tuoi dati aziendali',
                account: 'nuovo account',
                verify: 'Verifica i tuoi dati e {browse} sul sito',
                browse: 'inizia a navigare',
            },
            join: {
                title: 'Partecipa alle aste',
                choose: 'Scegli un {bus} tra quelli presenti nella lista',
                bus: 'autobus',
                add: 'Aggiungi un {payment} valido per iniziare a fare offerte',
                payment: 'metodo di pagamento',
                follow: "Segui l'asta e {win}",
                win: 'aggiudicati i tuoi autobus preferiti',
            },
            sell: {
                title: 'Vendi i tuoi autobus aziendali',
                letUsKnow: 'Comunicaci tutti i dettagli dell {bus}',
                bus: 'autobus da vendere',
                choose: 'Scegli un {price} per il tuo autobus',
                price: 'prezzo minimo', // TODO
                wait: "Attendi che l'asta termini per {confirm}",
                confirm: 'confermare la vendita',
            },
        },
        whyFleequid: {
            title: 'Perché scegliere Fleequid?',
            description: 'Vogliamo diventare il tuo partner di fiducia nella gestione della tua flotta',
            section: {
                euReach: {
                    title: 'Copertura europea',
                    description: 'Allarga il tuo raggio d’azione interagendo con operatori in tutta Europa.',
                },
                unifiedBilling: {
                    title: 'Fatturazione unificata',
                    description: 'Goditi la comodità di avere un’unica ragione sociale a cui fatturare.',
                },
                easyAuctions: {
                    title: 'Aste facili e convenienti',
                    description: 'Grazie al nostro servizio di supporto pre- e post-acquisto, partecipare alle aste sarà un gioco da ragazzi.',
                },
                operationalEfficiency: {
                    title: 'Efficienza operativa',
                    description: 'Risparmia tempo evitando gli indecisi e semplificando le pratiche amministrative e documentali.',
                },
                sell: {
                    title: 'Vendi i tuoi mezzi',
                    description: 'Fleequid ti supporta nella definizione del prezzo di vendita e nella creazione del dossier fotografico.',
                    cta: 'Vendi su Fleequid',
                },
            },
        },
        startToday: {
            title: 'Inizia oggi su Fleequid',
            description: 'Visualizza tutte le aste disponibili e preparati ad aggiudicarti i migliori prodotti',
            cta: 'Partecipa alle aste',
        },
    },
    faq: {
        title: 'Domande frequenti',
        start: {
            base: 'Come iniziare',
            participate: {
                q: "Come si partecipa a un'asta online?",
                a: "Per partecipare alle aste online su Fleequid è necessario registrarsi sul portale, alla pagina {0}, fornendo le informazioni relative alla propria azienda e inviando i documenti richiesti. Una volta fatto questo, è possibile visitare le pagine relative alle aste in corso ed effettuare una o più puntate per competere all’acquisto di un veicolo. Le aste si svolgono telematicamente e le offerte possono essere inserite fino alla chiusura dell'asta. L'asta si conclude con l'aggiudicazione del veicolo all'utente che ha presentato l'offerta più alta, a condizione che questa sia pari o superiore al prezzo di riserva, oppure in seguito all'accettazione di un'offerta inferiore al prezzo di riserva da parte del venditore.",
                link: 'https://www.fleequid.com/it/signup',
            },
            reservePrice: {
                q: "Cos'è il prezzo di riserva?",
                a: "Il prezzo di riserva è l'importo minimo da superare perché l’asta possa concludersi con successo. Se nel corso dell’asta non dovesse essere raggiunto, spetta al venditore del veicolo decidere se è disposto ad accettare comunque la migliore offerta ricevuta. È un importo più alto della base d’asta: viene deciso dal venditore prima della partenza dell'asta e non sarà visibile al pubblico, che conosce solo il valore della puntata massima fatta fino a quel momento.",
            },
            countries: {
                q: 'In quali paesi è possibile comprare e vendere autobus tramite Fleequid?',
                a: 'Fleequid opera in Italia, in Unione Europea e in diversi Paesi Extra UE, attenendosi alle regolamentazioni delle singole nazioni. Se hai dubbi sulla possibilità di utilizzare Fleequid dal tuo Paese contatta il servizio clienti scrivendo a {0}.',
            },
            bureaucracy: {
                q: 'Chi si occupa dei passaggi di proprietà, della nazionalizzazione ed in genere degli aspetti documentaliChi si occupa dei passaggi di proprietà, della nazionalizzazione ed in genere degli aspetti documentali?',
                a: 'Fleequid comunicherà a venditore e compratore come gestire gli aspetti documentali, e i pagamenti relativi al passaggio di proprietà, in base alle specificità della compravendita in atto (tra aziende italiane, UE, extra UE) e in base al ruolo stesso della piattaforma nel processo di compravendita. La registrazione del veicolo nel Paese di destinazione è comunque a carico e a cura dell’acquirente.',
            },
            toBeScraped: {
                q: 'Cosa sono i veicoli destinati alla demolizione?',
                a: "I veicoli destinati alla demolizione sono quelli che, non essendo più idonei alla circolazione e al trasporto di persone, devono necessariamente, per leggi vigenti o regolamenti di gara, essere sottoposti ad un processo di recupero e riutilizzo, anche parziale, delle sue componenti come pezzi di ricambio. La partecipazione alle aste per questi veicoli è consentita solo a demolitori iscritti all'albo gestori dei rifiuti in cat. 5 e gestori del codice C.E.R. 16.01.04. Attualmente, il servizio di asta di veicoli destinati alla demolizione è disponibile solo sul territorio italiano",
            },
            vies: {
                q: 'Che cos’è il Vies? Perché è obbligatorio esservi inclusi?',
                a: 'L’archivio Vies (VAT information exchange system, sistema per lo scambio di informazioni sull’IVA) è un motore di ricerca (non una banca dati) creato dalla Commissione Europea per facilitare le compravendite tra aziende appartenenti agli Stati membri. Dato che è necessario essere inclusi nell’archivio Vies per poter effettuare operazioni intracomunitarie, le aziende europee che vogliono registrarsi su Fleequid devono necessariamente esservi presenti.',
            },
        },
        sell: {
            base: 'Come vendere',
            selling: {
                q: 'Come posso iniziare a vendere su Fleequid?',
                a: 'Se hai un autobus da vendere contatta il nostro servizio clienti scrivendo a {0} oppure compila il form che trovi alla pagina {1} inserendo le informazioni relative al veicolo.  È necessario, inoltre, registrarsi come venditore sulla piattaforma.',
            },
            startingPrice: {
                q: 'Come si definisce la base d’asta?',
                a: "La base d'asta è il prezzo minimo del veicolo al di sopra del quale i potenziali acquirenti possono inserire le loro offerte o effettuare rilanci sul Marketplace. La base d’asta è calcolata da Fleequid sulla base delle sue valutazioni tecniche effettuate sul mezzo ed è necessariamente più bassa del prezzo di riserva, che è invece stabilito dal venditore.",
            },
            delivery: {
                q: 'Come avviene la consegna del mezzo?',
                a: 'Dopo aver ricevuto il pagamento, il venditore deve mettere l’acquirente nelle condizioni di ritirare il veicolo; l’acquirente deve procedere celermente al ritiro, che deve avvenire entro dieci giorni dalla data in cui Fleequid comunica al venditore il completamento delle pratiche relative alla de-registrazione nel Paese di origine. Per ogni giorno di ritardo successivo alla tempistica sopra indicata, Fleequid applica una penale.',
            },
            howLong: {
                q: 'In quanto tempo l’autobus viene venduto?',
                a: 'Quando un veicolo viene caricato sulla piattaforma vengono comunicati la data di inizio e di fine dell’asta – che sono stabilite da Fleequid in base alle specificità del veicolo da vendere – e i tempi di visibilità pre-asta. Qualora la vendita non dovesse concludersi con successo Fleequid avrebbe comunque il diritto di effettuare fino a 3 aste consecutive in complessivi 30 giorni.',
            },
        },
        buy: {
            base: 'Come comprare',
            inspection: {
                q: 'È possibile ispezionare i veicoli prima di fare offerte?',
                a: 'L’utente venditore può decidere se offrire l’opzione di visita dal vivo al veicolo. Se lo permette, verrà definito un giorno in cui sarà possibile effettuare visite dal vivo e ispezioni del mezzo direttamente presso l’utente venditore.',
            },
            claims: {
                q: 'Come gestite vizi occulti e reclami?',
                a: "Fleequid è un intermediario tra venditore e compratore e, come tale, non è responsabile di eventuali vizi, anche occulti, difetti, usura, irregolarità, inidoneità all'uso, come di errori o imprecisioni delle dichiarazioni rese dal venditore. Pubblicando il suo annuncio su Fleequid il venditore si impegna alla trasparenza e a dare indicazioni reali sullo stato del veicolo; allo stesso tempo, il compratore deve impegnarsi a segnalare in tempi brevi eventuali incongruenze (3 giorni per i vizi apparenti) ed entro un mese dal ritiro i vizi occulti del veicolo. Fleequid è a disposizione dell’acquirente per la gestione del reclamo e fornisce condizioni specifiche per la loro gestione.",
            },
            paymentMethods: {
                q: 'Quali metodi di pagamento sono accettati?',
                a: 'Fleequid accetta bonifici bancari e pagamenti con carta di credito. Nel dettaglio, è possibile versare il deposito cauzionale solo con carta di credito, mentre per l’acquisto del veicolo e il pagamento delle commissioni e di eventuali spese accessorie deve avvenire tramite bonifico bancario.',
            },
            howPaymentWorks: {
                q: 'Come funziona il pagamento?',
                a: "L’utente che si è aggiudicato l’asta è tenuto a corrispondere un importo che comprende il prezzo di aggiudicazione, le commissioni di vendita per Fleequid e ogni altra tariffa concordata (confronta le Condizioni Generali per maggiori dettagli). L'acquirente deve pagare quanto dovuto mediante bonifico bancario entro e non oltre tre giorni dalla ricezione della fattura corrispondente. Sono vietati i pagamenti parziali.",
            },
            deposit: {
                q: 'Come funziona il deposito cauzionale?',
                a: "Il deposito cauzionale è la somma di denaro che l'utente versa a Fleequid al momento della formulazione di un'offerta in asta, come garanzia della serietà dell'offerta stessa. Quando l’asta finisce tutti i depositi vengono rilasciati, ad eccezione di quelle del primo e secondo classificato. Se Il vincitore finalizza l’acquisto del mezzo, effettuando il bonifico per la cifra concordata  (entro e non oltre 3 giorni dalla ricezione della fattura corrispondente), vengono rilasciati sia il suo deposito sia quello del secondo. Nel caso in cui il vincitore non pagasse nei tempi concordati, perderebbe sia l’autobus sia il deposito cauzionale. In questo caso il secondo classificato sarà di diritto il nuovo vincitore dell'asta e, acquistando con bonifico il veicolo, si vedrà restituito il deposito cauzionale.",
            },
        },
        contactUs:
            'Ci auguriamo che tu abbia trovato in questa pagina tutte le risposte alle tue domande, ma in caso contrario siamo sempre felici di aiutarti. Chiamaci al {0}, oppure contattaci scrivendo a {1}',
    },
    footer: {
        cookiePolicy: {
            base: 'Cookie policy',
            changeConsent: 'Cambia il tuo consenso',
            revokeConsent: 'Revoca il tuo consenso',
        },
        explore: 'Naviga',
        legal: 'Informazioni legali',
        support: 'Hai bisogno di supporto?',
        vat: 'P.IVA',
        backTop: 'Torna in alto',
    },
    menu: {
        buy: 'Compra',
        sell: 'Vendi',
        faq: 'F.A.Q.',
    },
    mktg: {
        whyFleequid: {
            title: 'Perché Fleequid?',
            deal: {
                title: 'Compravendite semplificate',
                description: 'Una piattaforma tecnologica al posto di trattative con interlocutori indecisi',
            },
            processes: {
                title: 'Processi veloci',
                description: 'Fleequid riduce i tempi e fa risparmiare energie al tuo staff',
            },
            support: {
                title: 'Un supporto trasparente',
                description: 'Tutto è chiaro, dalla registrazione alle fasi di consegna del mezzo',
            },
        },
    },
    notifications: {
        success: 'Successo',
        error: 'Errore',
        genericError: 'Si è verificato un errore. Riprova più tardi.',
        tryAgain: 'Si prega di riprovare',
        userOutBid: {
            base: 'La tua offerta di {prevBidAmount} per {subject} è stata superata, il nuovo prezzo corrente è {currentBidAmount}',
            inAuction: 'questa asta',
            linkedAuction: '{brand} {model} {version}',
        },
        userBailAuthorized: {
            inAuction: 'Sei stato autorizzato a partecipare a quest’asta',
            linkedAuction: 'Sei stato autorizzato a partecipare all’asta {brand} {model}',
        },
        userMaxBidderRestored: {
            base: 'La tua offerta di {prevBidAmount} per {subject} è la migliore!',
            inAuction: 'questa asta',
            linkedAuction: '{brand} {model} {version}',
        },
    },
    privacy: {
        link: 'Informativa Privacy',
    },
    seo: {
        auction: {
            title: '{asset} {brand} {model} {version} {vin}',
            description:
                'Acquista {brand} {model} {version}, {asset} usato su Fleequid.com: partecipa all’asta B2B del e fai un’offerta per {asset} {vin} del brand {brand}, modello {model} e versione {version}.',
        },
        auctions: {
            title: '{0} usati in vendita',
            description: 'Acquista online {0} usati su Fleequid.com : partecipa facilmente alle aste B2B dei i migliori {1} usati all’asta.',
        },
        cookiePolicy: {
            title: 'Cookie Policy',
            description:
                'La gestione dei cookies relativa al portale Fleequid: leggi come gestiamo i cookies che utilizziamo per rendere la navigazione confortevole.',
        },
        homepage: {
            title: 'Vendita Autobus Usati',
            description:
                "Acquista o Vendi autobus e veicoli industriali all'asta: entra in Fleequid e partecipa facilmente alle aste B2B dei migliori veicoli industriali.",
        },
        privacyPolicy: {
            title: 'Privacy Policy',
            description:
                'Informativa sulla privacy relativa al portale Fleequid: ti illustriamo chiaramente come gestiamo i dati di navigazione e tutti i tuoi dati.',
        },
        signup: {
            title: 'Aste Veicoli: Registrazione',
            description:
                'Registrati ora a Fleequid per partecipare nel modo più facile e comodo alle aste di veicoli industriali, autobus e pullman nuovi e usati.',
            confirm: {
                title: 'Verifica email',
            },
        },
        termsAndConditions: {
            title: 'Termini e Condizioni',
            description:
                "Termini e condizioni di vendita e acquisto su Fleequid, il portale online di vendita all'asta e acquisto veicoli industriali e autobus.",
        },
        pricingSeller: {
            title: 'Commissioni Venditore',
            description:
                'Commissioni d’asta applicate su Fleequid.com: dettagli sui prezzi e specifiche sul metodo di applicazione delle commissioni relative alle aste di vendita.',
        },
        pricingBuyer: {
            title: 'Commissioni Acquirente',
            description:
                'Commissioni d’asta applicate su Fleequid.com: dettagli sui prezzi e specifiche sul metodo di applicazione delle commissioni relative alle aste di vendita.',
        },
    },
    signup: {
        link: 'Registrati',
        companyRegistration: 'Registra la tua azienda',
        subtitle: 'Inserisci i dati della tua azienda ed entra in un mondo nuovo di offerte.',
        companyInfo: 'Dati aziendali',
        companyDocuments: 'Documenti aziendali',
        contactPerson: 'Dati referente aziendale',
        accessCredentials: 'Credenziali di accesso',
        fleetHandling: 'Movimentazione flotta',
        fields: {
            address: 'Indirizzo',
            postCode: 'CAP',
            city: 'Città',
            province: 'Provincia',
            businessName: 'Ragione sociale',
            country: 'Nazione',
            pec: 'PEC',
            sdi: 'Codice SDI',
            vatId: 'Partita IVA/VAT ID',
            companyRegistrationReportFile: 'Visura camerale',
            firstname: 'Nome',
            lastname: 'Cognome',
            phone: 'Numero di telefono',
            type: {
                label: 'Tipologia operatore',
                [UserType.Dealer]: 'Commerciante',
                [UserType.PeopleTransportOperator]: 'Operatore di trasporto passeggeri',
                [UserType.Scrapyard]: 'Demolitore',
                [UserType.Other]: 'Altro',
            },
            email: 'Email',
            password: 'Password',
            confirmPassword: 'Conferma password',
            stats: {
                sales: 'Quanti autobus pensi di vendere nel {year}?',
                purchase: 'Quanti autobus pensi di acquistare nel {year}?',
                unknown: 'Non lo so',
            },
            consent: 'Consenso',
            termsConditions: 'Ho preso visione e accetto i {0} del servizio.',
            vexatiousClauses: 'Ho preso visione e accetto le seguenti clausole:',
            privacy: "Ho preso visione e accetto l'{0}.",
        },
        hint: {
            phone: {
                text: 'Formato numero',
                rule: 'Inserisci il telefono nel formato +39 1234567890',
            },
        },
        validations: {
            requiredSDIOrPec: 'Inserisci almeno uno tra codice SDI o indirizzo PEC',
            companyRegistration: 'Il file selezionato è troppo grande. La dimensione massima consentita è di {size} MB',
        },
        success: {
            title: 'Grazie mille per aver completato la registrazione!',
            p1: 'I tuoi dati sono stati ricevuti con successo.',
            p2: 'A breve riceverai un’email di conferma, contenente un link su cui cliccare per verificare il tuo indirizzo email.',
            p3: 'Ti invitiamo a controllare anche nella cartella spam della tua casella di posta poiché, a causa delle preferenze da te configurate, l’email potrebbe essere stata contrassegnata come posta indesiderata.',
            p4: 'Ti invitiamo a visualizzare le aste online e scoprire se ci sono veicoli di tuo interesse. Vuoi vendere i tuoi autobus alla prossima asta? Contattaci a {0}.',
            p5: 'Grazie per averci scelto!',
        },
        confirm: {
            success: {
                title: 'La tua email è stata verificata!',
                p1: 'Grazie per aver confermato la tua email {email}.',
                p2: 'Ora il tuo account è attivo e puoi accedere a tutte le funzionalità del nostro servizio. ',
                p3: 'Se hai domande o necessiti di assistenza, contatta il nostro supporto a {0}. Benvenuto a bordo!',
            },
            error: {
                title: "Verifica dell'email non riuscita",
                p1: 'Siamo spiacenti, ma la verifica della tua email non è andata a buon fine.',
                p2: 'Potrebbe esserci stato un problema con il link di conferma. ',
                p3: 'Ti invitiamo a riprovare cliccando sul link di verifica che ti abbiamo inviato. Se il problema persiste, contatta il nostro supporto per assistenza a {0}.',
            },
            buyCta: 'Partecipa alle aste',
            hpCta: 'Torna alla homepage',
        },
    },
    termsConditions: {
        link: 'Termini e Condizioni',
    },
    login: {
        email: 'Email',
        password: 'Password',
        formSubtitle: 'Inserisci le tue credenziali Fleequid per partecipare alle aste',
        forgotPassword: 'Password dimenticata?',
        or: 'Oppure',
        unregistered: 'Non hai un account Fleequid?',
        signupNow: 'Registrati subito',
    },
    passwordReset: {
        request: {
            title: 'Reimposta la password',
            subTitle: 'Inserisci la tua email e ti invieremo un messaggio per reimpostare la password.',
            submitButton: 'Invia email',
            onSuccessTitle: 'Richiesta accettata',
            onSuccessDescription: 'Riceverai un email con la procedura per completare la reimpostazione della password.',
        },
        reset: {
            title: 'Reimposta la password',
            description: 'Inserisci una nuova password. Una volta confermata la modifica tutte le sessioni attive saranno invalidate.',
            newPassword: 'Nuova password',
            confirmNewPassword: 'Conferma nuova password',
            submitButton: 'Modifica password',
            updateSuccess: 'Password modificata correttamente',
            tokenExpired: 'Il token per reimpostare la password è scaduto.',
            tokenInvalid: 'Il token per reimpostare la password è invalido.',
            startOverDescription:
                'Esegui nuovamente la procedura per richiedere di reimpostare la password, contestualmente sarà generato un nuovo token.',
            startOverButton: 'Riprova',
        },
    },
    validation: {
        msg: {
            characters: 'Uno o più caratteri non validi',
            email: "L'email deve avere un formato user{'@'}domain.ext",
            incomplete: '{field} incompleto',
            invalid: '{field} invalido',
            invalidBid: "L'importo inserito non è valido",
            missing: '{field} mancante',
            max: 'Lunghezza massima {count} carattere | Lunghezza massima {count} caratteri',
            min: 'Lunghezza minima {count} carattere | Lunghezza minima {count} carattere',
            length: 'La lunghezza deve essere esattamente di {count} carattere | La lunghezza deve essere esattamente di {count} caratteri',
            passwordWeak: 'Password troppo debole',
            passwordMatch: 'Le password non corrispondono',
            phone: 'Inserire solo numeri, spazi e il carattere +',
            recaptcha: 'Errore validazione reCAPTCHA',
            required: '{field} obbligatorio',
        },
        hint: {
            password: {
                text: 'Regole per una password efficace',
                rules: {
                    length: 'Almeno 8 caratteri',
                    uppercase: '1 carattere maiuscolo',
                    lowercase: '1 carattere minuscolo',
                    symbol: '1 carattere speciale',
                    number: '1 numero',
                },
            },
        },
    },
    profile: {
        title: 'Il tuo profilo',
        companyData: {
            readOnlyDisclaimer: 'I dati aziendali sono in sola lettura, per richiederne la modifica contattaci a {0}.',
        },
        userData: {
            saveChanges: 'Conferma nuovi dati',
            cancelChanges: 'Annulla modifiche',
            updateSuccess: 'Dati referente aziendale salvati correttamente',
        },
        changePassword: {
            title: 'Modifica password',
            description: 'La modifica della password invaliderà tutte le altre sessioni attive ad esclusione di quella corrente.',
            currentPassword: 'Password corrente',
            newPassword: 'Nuova password',
            confirmNewPassword: 'Conferma nuova password',
            submitButton: 'Conferma nuova password',
            updateSuccess: 'Password modificata correttamente',
        },
        changeEmail: {
            title: 'Modifica email',
            description:
                "Riceverai un'email con un collegamento per confermare la nuova email. La modifica della tua email invaliderà tutte le sessioni attive.",
            password: 'Password corrente',
            email: 'Email',
            submitButton: 'Conferma nuova email',
            updateSuccess: 'Email modificata correttamente',
        },
    },
    sessionExpired: {
        title: 'La tua sessione è scaduta',
        description: 'La sessione è stata invalidata perché le credenziali di accesso sono state cambiate. Accedi con le nuove credenziali.',
        close: 'Continua',
    },
};

export default it;
