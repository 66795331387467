/*
    N.B. Keep this comment to preserve line number alignment among locale files:
    Same line = same translation entry
 */

import {
    AssetDoors,
    AssetEuroNorm,
    AssetFuel,
    AssetGearboxType,
    AssetSeatUpholstery,
    AssetType,
    AssetWheelchairRamp,
    AuctionState,
    BackendErrorCode,
    BusClass,
    CruiseControlType,
    DeckType,
    GasTanksMaterials,
    Glasses,
    RequireScrappingFilter,
    UserType,
    VehicleCondition,
} from '~/apiClient';

const en = {
    account: {
        bids: {
            title: 'Your bids',
            emptyState: {
                base: "You haven't placed a bid in any auction yet,",
                cta: 'Participate in auctions',
            },
            filters: {
                bidPosition: {
                    base: 'Bid position',
                    anyOption: 'All bid positions',
                    maxBidder: 'Your bid is the highest',
                    outbid: 'You have been outbid',
                },
                state: {
                    base: 'Auction state',
                    anyOption: 'All auction states',
                    [AuctionState.Closed]: 'Closed auctions',
                    [AuctionState.Running]: 'Running auctions',
                },
            },
        },
        sales: {
            title: 'Your sales',
            emptyStateText: 'There are no auctions with a seller associated with this account.',
        },
    },
    common: {
        logout: 'Logout',
        login: 'Login',
        loginSignup: 'Login/Signup',
        loginTitle: 'Login to Fleequid',
        profile: 'Your profile',
        searchPlaceholder: 'Search...',
        searchNoResults: 'No results found for "{query}"',
        email: 'Email',
        yourAccount: 'Your account',
    },
    cookie: {
        name: 'Name',
        provider: 'Provider',
        purpose: 'Purpose',
        expiry: 'Expiry',
        type: 'Type',
    },
    error: {
        backend: {
            [BackendErrorCode.InvalidEnumValue]: 'Invalid data type',
            [BackendErrorCode.InvalidDate]: 'Invalid date format',
            [BackendErrorCode.InvalidLength]: 'Invalid length. Verify the string for missing or extra characters',
            [BackendErrorCode.NotAString]: 'Invalid data type; only letters are allowed',
            [BackendErrorCode.EmptyString]: 'Missing Information. Please complete all required data',
            [BackendErrorCode.PrismaError]: 'The data could not be saved; please try a different value',
            [BackendErrorCode.AuthAuthenticationVersionMismatch]: 'Your session has expired. Please log in again to continue',
            [BackendErrorCode.AuthWrongCredentials]: 'Invalid credentials',
            [BackendErrorCode.AuthNoUserByEmail]: 'There is no account associated with the email address entered.',
            [BackendErrorCode.AuthWrongPassword]: 'The password entered is incorrect.',
            [BackendErrorCode.UserIsBanned]: 'Your account has been suspended. Contact us for further information.',
            [BackendErrorCode.AuthEmailNotVerified]: 'The email address entered is not yet verified. We sent you a new email verification link.',
            [BackendErrorCode.SignupUserAlreadyExists]: 'Email address already registered, if you have forgotten your password try to recover it.',
            [BackendErrorCode.UploadFileError]: 'Error while uploading your file',
            [BackendErrorCode.IsNotTrue]: 'Missing selection: Choose the required option to continue',
            [BackendErrorCode.EntityNotFound]: 'No Results Found',
            [BackendErrorCode.PasswordResetEmailNotExists]: 'There is no account associated with this email',
            [BackendErrorCode.EmailChangeWrongPassword]: 'You cannot change the email address because the password is not correct',
            [BackendErrorCode.CantBidUserCompanyEqSellerCompany]: 'This asset is from your company, you cannot buy it.',
            authentication: 'Authentication error; please try logging in again',
        },
        '404': {
            title: '404',
            subtitle: 'Oops! Page not found',
            description: "Sorry, the page you requested does not exist. Anyway you'll find plenty more to explore on Fleequid.",
            button: 'Back to homepage',
        },
        '500': {
            title: '500',
            subtitle: 'Oops! An error occurred',
            description: 'We apologize for the inconvenience. We are already working on the problem.',
            help: {
                base: 'In the meantime you can try later or {contactUs}',
                contactUs: 'contact us at {email}, or {phone} for emergencies',
            },
            button: 'Back to homepage',
        },
        default: {
            title: 'Error',
            subtitle: 'We have encountered an unexpected error',
            description: 'We are already working to solve the problem.',
            button: 'Back to homepage',
        },
    },
    a11y: {
        auctionDetail: 'Learn more about {name}',
    },
    adp: {
        imageGallery: 'Complete gallery ({totalImages})',
        videoGallery: 'Watch videos ({totalVideos})',
        imagePagination: 'Photo {0} of {1}',
        videoPagination: 'Video {0} of {1}',
        papers: 'Papers',
        registrationDocument: 'Vehicle registration document',
        cocDocument: 'C.O.C.',
        maintenanceCard: 'Maintenance history',
        detailsTitle: 'Vehicle details',
    },
    asset: {
        type: {
            [AssetType.Bus]: 'Bus | Buses',
        },
        class: {
            base: 'Class',
            [BusClass.ClassOne]: 'City',
            [BusClass.ClassTwo]: 'Intercity',
            [BusClass.ClassThree]: 'Touristic',
            [BusClass.ClassA]: 'Class A',
            [BusClass.ClassB]: 'Class B',
            [BusClass.SchoolBus]: 'School Bus',
        },
        firstRegistration: 'First registration',
        kilometers: 'Kilometers',
        antiPollution: 'Anti Pollution',
        euroNorm: {
            [AssetEuroNorm.Euro0]: 'Euro 0',
            [AssetEuroNorm.Euro1]: 'Euro 1',
            [AssetEuroNorm.Euro2]: 'Euro 2',
            [AssetEuroNorm.Euro3]: 'Euro 3',
            [AssetEuroNorm.Euro4]: 'Euro 4',
            [AssetEuroNorm.Euro5]: 'Euro 5',
            [AssetEuroNorm.Euro6]: 'Euro 6',
        },
        toBeScraped: 'To be scraped',
        expiredInspection: 'Inspection expired',
        additionalNotes: 'Additional notes',
        description: 'Description',
        version: 'Version',
        geographicalLocation: 'Location',
        vehicleCondition: {
            base: 'Vehicle condition',
            [VehicleCondition.Blocked]: 'Blocked',
            [VehicleCondition.Rollable]: 'Towable',
            [VehicleCondition.Running]: 'Running',
        },
        length: 'Length',
        height: 'Height',
        width: 'Width',
        vin: 'V.I.N.',
        converter: 'Converter',
        visitDate: {
            base: 'Viewable',
            fromTo: 'on {date} from {fromTime} to {toTime}',
        },
        inspectionExpiryDate: 'Inspection expiration date',
        doors: {
            base: 'Doors',
            [AssetDoors.Double]: '2',
            [AssetDoors.DoubleDouble]: '2+2',
            [AssetDoors.DoubleDoubleDouble]: '2+2+2',
            [AssetDoors.DoubleDoubleSingle]: '2+2+1',
            [AssetDoors.DoubleSingleSingle]: '2+1+1',
            [AssetDoors.Single]: '1',
            [AssetDoors.SingleDouble]: '1+2',
            [AssetDoors.SingleSingle]: '1+1',
            [AssetDoors.SingleDoubleSingle]: '1+2+1',
            [AssetDoors.SingleSingleSingle]: '1+1+1',
            [AssetDoors.SingleDoubleDouble]: '1+2+2',
        },
        seatUpholstery: {
            base: 'Seat upholstery',
            [AssetSeatUpholstery.Fabric]: 'Fabric',
            [AssetSeatUpholstery.Leather]: 'Leather',
            [AssetSeatUpholstery.Plastic]: 'Plastic',
            [AssetSeatUpholstery.FabricLeather]: 'Fabric Leather',
        },
        seats: 'Seats',
        totalCapacity: 'Total passenger capacity',
        seatsFromS1: "Seats including driver's seat from registration certificate S1",
        standingPlaces: 'Standing places',
        standingPlacesFromS2: 'Standing place from registration certificate S2',
        wheelchairPlaces: 'Wheelchair places',
        companionSeats: 'Companion seats',
        wheelchairRamp: {
            base: 'Wheelchair ramp',
            [AssetWheelchairRamp.Absent]: 'Absent',
            [AssetWheelchairRamp.Electric]: 'Electric',
            [AssetWheelchairRamp.Manual]: 'Manual',
            [AssetWheelchairRamp.Electropneumatic]: 'Electro-pneumatic',
        },
        luggageCapacity: 'Luggage capacity',
        passThroughLuggageCompartment: 'Pass-through Luggage Compartment',
        hatBox: 'Hat Box',
        transmission: 'Transmission',
        gearboxType: {
            base: 'Transmission type',
            [AssetGearboxType.Auto]: 'Automatic',
            [AssetGearboxType.Manual]: 'Manual',
            [AssetGearboxType.SemiAuto]: 'Semi-automatic',
        },
        gearboxBrand: 'Transmission brand',
        gearboxModel: 'Transmission model',
        glasses: {
            base: 'Windows',
            [Glasses.Single]: 'Single pane',
            [Glasses.Double]: 'Double pane',
        },
        openableWindows: 'Operational windows',
        engine: 'Engine',
        engineBrand: 'Engine brand',
        engineModel: 'Engine model',
        hp: 'Horsepower',
        kw: 'Power',
        displacement: 'Displacement',
        cylinders: 'Number of cylinders',
        fuel: {
            base: 'Fuel',
            [AssetFuel.CNG]: 'CNG',
            [AssetFuel.Electric]: 'Electric',
            [AssetFuel.GPL]: 'LPG',
            [AssetFuel.Diesel]: 'Diesel',
            [AssetFuel.Gasoline]: 'Gasoline',
            [AssetFuel.Hybrid]: 'Hybrid',
            [AssetFuel.Hydrogen]: 'Hydrogen',
        },
        fuelTankCapacity: 'Fuel tank capacity',
        gasTanksCapacity: 'Gas tanks',
        gasTanksMaterial: {
            base: 'Fuel tank material',
            [GasTanksMaterials.Aluminum]: 'Aluminium',
            [GasTanksMaterials.Composite]: 'Composite',
            [GasTanksMaterials.Steel]: 'Steel',
        },
        batteryPower: 'Battery capacity',
        chargingType: 'Charging type',
        axlesTires: 'Axles and tires',
        wheelSize: 'Wheel size',
        wheels: 'number of wheels (single or double)',
        axles: 'Axles',
        wheelsSizeAxle1: 'Tire size for axle 1',
        wheelsSizeAxle2: 'Tire size for axle 2',
        wheelsSizeAxle3: 'Tire size for axle 3',
        alloyWheels: 'Alloy wheels',
        spareWheel: 'Spare wheel',
        firePreventionSystem: 'Fire alarm system',
        firePreventionSystemBrand: 'Fire alarm system brand',
        abs: 'ABS',
        asr: 'ASR',
        retarder: 'Retarder',
        breathalyzer: 'Breathalyzer',
        tachograph: 'Tachograph',
        indicatorSigns: 'Destination signs',
        indicatorSignsBrand: 'Destination signs brand',
        accessories: 'Accessories',
        cruiseControl: {
            base: 'Cruise control',
            [CruiseControlType.No]: 'No',
            [CruiseControlType.Adaptive]: 'Adaptive',
            [CruiseControlType.Standard]: 'Standard',
        },
        deckType: {
            base: 'Floor',
            [DeckType.LowEntry]: 'Low Entry',
            [DeckType.LowFloor]: 'Low Floor',
            [DeckType.NormalFloor]: 'Normal Floor',
        },
        acDriver: 'Driver air conditioning',
        driverBerth: 'Driver berth',
        wifi: 'Wi-Fi',
        cctv: 'CCTV',
        internalCameras: 'Internal cameras',
        externalCameras: 'External cameras',
        retrocamera: 'Reversing camera',
        microphone: 'Microphone',
        cdPlayer: 'CD player',
        dvdPlayer: 'DVD player',
        usbPlayer: 'Radio USB player',
        heater: 'Heater',
        preHeater: 'Preheater',
        kitchen: 'Kitchen',
        coffeeMachine: 'Coffee machine',
        refrigerator: 'Refrigerator',
        toilet: 'Toilet',
        safetyBelts: 'Safety belts',
        acPassengers: 'Passengers air conditioning',
        curtains: 'Curtains',
        seatPocket: 'Seat pocket',
        footRest: 'Foot rest',
        handRest: 'Hand rest',
        seatsMonitor: 'Seats monitor',
        seatsUsbPorts: 'Seats USB port',
        socket220: 'Driver Electric socket',
        seatsSocket220: 'Passenger seat electric socket 220V',
        seatsRadio: 'Seats radio',
        extendableSeats: 'Extendable seats',
        recliningSeats: 'Reclining seats',
        coffeeTables: 'Coffee tables',
    },
    auction: {
        countdown: {
            startsIn: 'Starts in',
            endsIn: 'Ends in',
            days: 'd',
            hours: 'h',
            minutes: 'm',
            seconds: 's',
        },
        bail: {
            title: 'Bind the security deposit to participate in the auction',
            description: 'To participate in this auction, you must bind the security deposit using a valid payment method.',
            amount: 'SECURITY DEPOSIT',
            note: 'The security deposit will be released at the end of the auction if you are not among the highest bidders.',
            subnote: 'Security deposits are not the same for all assets and may vary depending on the auction base',
            error: 'An error occurred while processing the transaction, please try again later or contact support',
            submit: 'Bind the security deposit',
        },
        bid: {
            title: 'Become the top bidder now',
            description: 'Remember that the bid is binding, and if your bid is not exceeded, you are committed to paying this amount.',
            yourNewOffer: 'Your new Offer',
            confirm: 'Confirm the offer right away',
            cancel: 'Cancel',
            success: 'Bid successfully placed!',
            total: 'Total',
            outbidError: {
                title: 'Another user has exceeded your offer',
                description:
                    "The offer you've chosen has been outbid by another user. Please close this window and refresh your search to see the latest offers.",
                close: 'Close',
            },
            expiredError: {
                title: 'The auction is closed',
                description: 'No further bids can be placed.',
            },
        },
        bidButton: 'Place your bid',
        auctionPanel: {
            reservePrice: 'Reserve price',
            startingPrice: 'Starting price',
            current: 'Current',
            wonAuction: 'Auction won!',
            state: {
                [AuctionState.Closed]: 'Closed auction',
                [AuctionState.Canceled]: 'Canceled auction',
                [AuctionState.Draft]: 'Draft auction',
            },
            maxBidder: 'Your bid is the highest!',
            outBid: 'You have been outbid!',
            negotiationInProgress: 'Negotiation in progress',
            feeNotice: '+ fees ({0})',
            feeNoticeLink: 'what\xa0are\xa0they?',
            priceNotAvailable: 'N/A',
            bidNotice: {
                reservePriceNotReached: {
                    base: 'Reserve price {0}',
                    notReached: 'not yet reached',
                },
                notScrapyard:
                    'Auction reserved for scrapyard workers registered in the register of waste managers in cat. 5 and managers of the C.E.R. code 16.01.04',
                sameCompany: {
                    [AuctionState.Running]: 'You cannot participate because the vehicle already belongs to your company',
                    base: 'The vehicle already belongs to your company',
                },
                isAdmin: 'You cannot participate because your user has the Admin role',
            },
        },
        needSupport: 'Do you need support?',
        contactUs: 'Contact Fleequid customer service',
    },
    auctions: {
        page: {
            asset: {
                [AssetType.Bus]: 'bus | buses',
            },
            title: 'Discover all {title} on Fleequid',
            results: '{total} visible auctions',
            emptyStateTitle: 'No results',
            emptyStateText: 'The applied filters did not produce any results.',
            resetFilters: 'Remove filters',
        },
        filters: {
            applied: 'Applied Filters',
            button: {
                label: 'Filter auctions',
            },
            modal: {
                title: 'Filter Auctions',
                back: 'Back to results',
            },
            label: 'Filters',
            class: 'Class',
            brand: 'Brand, model, version',
            brandFilter: 'Brand',
            brandFilterAnyOption: '',
            modelFilter: 'Model',
            modelFilterAnyOption: '',
            versionFilter: 'Version',
            versionFilterAnyOption: '',
            antiPollution: 'Anti Pollution',
            registration: 'Registration',
            yearFrom: 'From (year)',
            yearTo: 'To (year)',
            yearAnyOption: 'All years',
            kilometers: 'Kilometers',
            kmFrom: 'From',
            kmTo: 'To',
            kmAnyOption: 'Any mileage',
            state: {
                title: 'Auction state',
                all: 'All states',
                [AuctionState.Pending]: 'Not yet started auctions',
                [AuctionState.Running]: 'Running auctions',
                [AuctionState.Closed]: 'Closed auctions',
            },
            scrapping: {
                title: 'Scrapping restrictions',
                help: 'What are scrapping restrictions?',
                all: 'All vehicles',
                [RequireScrappingFilter.requireScrapping]: 'With scrapping restrictions',
                [RequireScrappingFilter.doNotRequireScrapping]: 'Without scrapping restrictions',
            },
            fuel: 'Fuel',
            length: 'Length',
            lengthFrom: 'From',
            lengthTo: 'To',
            lengthAnyOption: 'Any length',
            empty: 'No filters applied.',
        },
        sorting: {
            title: 'Sort by',
            yearAsc: 'Oldest registration',
            yearDesc: 'Most recent registration',
            priceAsc: 'Less expensive',
            priceDesc: 'More expensive',
            kmAsc: 'Increasing mileage',
            kmDesc: 'Decreasing mileage',
            defaultByState: {
                all: 'Default order',
                [AuctionState.Running]: 'Soonest end',
                [AuctionState.Closed]: 'Most recent end',
                [AuctionState.Pending]: 'Soonest start',
            },
        },
        appliedFilters: {
            from: 'from: {0}',
            to: 'to: {0}',
            reset: 'Reset filters',
            appliedFiltersCounter: '{count} active filter | {count} active filters',
        },
    },
    homepage: {
        link: 'Home',
        hero: {
            title: 'There are no heavy vehicles',
            subtitle: 'Join our online auctions and buy or sell your bus in a simple, fast and transparent way',
            buttonLabel: 'Participate in auctions',
        },
        whatIsFleequid: {
            title: 'What is Fleequid?',
            description: 'Fleequid is the new way to sell and buy vehicles at auction for your business',
            section: {
                simplicity: {
                    title: 'Simplicity',
                    description: 'A linear and intuitive process, which eliminates endless negotiations',
                },
                speed: {
                    title: 'Speed',
                    description: 'You can buy your new bus in 7 days',
                },
                transparency: {
                    title: 'Transparency',
                    description: 'Clear prices, detailed images, dedicated support',
                },
            },
        },
        vision: {
            caption: 'Our vision',
            title: 'We believe in the benefits of used vehicles. We want to help companies manage the buying and selling of company vehicles, saving time and resources, turning a problem into an opportunity.',
            highlight: 'Forget risks and uncertainties: with us, giving value to your assets becomes simple.',
        },
        howItWorks: {
            title: 'How it works',
            description: 'We strive to keep our platform simple and intuitive so you can easily manage your bus fleet',
            signUp: {
                title: 'Sign up for Fleequid',
                create: 'Create a {account} using your company details',
                account: 'new account',
                verify: 'Verify your details and {browse} the site',
                browse: 'start browsing',
            },
            join: {
                title: 'Participate in auctions',
                choose: 'Choose a {bus} from the list',
                bus: 'bus',
                add: 'Add a valid {payment} to start bidding',
                payment: 'payment method',
                follow: 'Follow the auction and {win}',
                win: 'win your favorite buses',
            },
            sell: {
                title: 'Sell your buses',
                letUsKnow: 'Let us know all the details of the {bus}',
                bus: 'bus for sale',
                choose: 'Choose a {price} for your bus',
                price: 'minimum price', // TODO
                wait: 'Wait for the auction to end to {confirm}',
                confirm: 'confirm the sale',
            },
        },
        whyFleequid: {
            title: 'Why choose Fleequid?',
            description: 'We want to become your trusted partner in managing your fleet',
            section: {
                euReach: {
                    title: 'European reach',
                    description: 'Save time by avoiding undecided people and simplifying administrative and documentary procedures.',
                },
                unifiedBilling: {
                    title: 'Unified billing',
                    description: 'Enjoy the convenience of having an efficient platform and a single company name to invoice.',
                },
                easyAuctions: {
                    title: 'Easy and convenient auctions',
                    description: "Thanks to our pre- and post-purchase support service, participating in auctions will be child's play.",
                },
                operationalEfficiency: {
                    title: 'Operational efficiency',
                    description: 'Save time by avoiding the indecisive and simplifying paperwork.',
                },
                sell: {
                    title: 'Sell your vehicles',
                    description: 'Fleequid supports you in setting the sales price and creating the photo dossier.',
                    cta: 'Sell on Fleequid',
                },
            },
        },
        startToday: {
            title: 'Start today on Fleequid',
            description: 'View all available auctions and get ready for the best products',
            cta: 'Participate in auctions',
        },
    },
    faq: {
        title: 'Frequently asked questions',
        start: {
            base: 'How to get started',
            participate: {
                q: 'How can I participate in an online auction?',
                a: 'To join online auctions on Fleequid you need to sign up at {0} providing your business information and sending the required documents. Once you’ve done that, you can check out the pages with the ongoing auctions, and place one or more bids to compete for the purchase of a vehicle. Auctions are held electronically, and you can place bids until the auction closes. The auction ends when the user who submitted the highest bid awards the vehicle, as long as the bid is at least the reserve price, or if the seller accepts a bid that is lower than the reserve price. ',
                link: 'https://fleequid.com/en/signup',
            },
            reservePrice: {
                q: 'What is the reserve price?',
                a: 'The reserve price is the minimum amount that must be exceeded for the auction to be successful. If this amount is not reached during the auction, it’s up to the seller to decide whether they are willing to accept the best offer they received anyway. The reserve price is a higher amount than the starting bid: it is set by the seller before the start of the auction and will not be visible to the public, who only knows the highest bid made up to that moment.',
            },
            countries: {
                q: 'In which countries is it possible to buy and sell buses through Fleequid?',
                a: 'Fleequid operates in Italy, in the European Union and in several non-EU countries, complying with the regulations of each nation. If you have any doubts about the possibility of using Fleequid from your country, please contact our customer service at {0}.',
            },
            bureaucracy: {
                q: 'Who is responsible for property transfers, vehicle registration and every documentation aspect?',
                a: "Fleequid will communicate to seller and buyer how to handle documentation aspects and payments related to the property transfer, depending on the specifics of the deal (between Italian companies, EU, and non-EU) and on the platform's role in the sales process. However, registration of the vehicle in the country of destination is the responsibility of the buyer.",
            },
            toBeScraped: {
                q: 'What are vehicles intended for scrapping?',
                a: 'Vehicles intended for scrapping are those which, being no longer suitable for circulation and the transportation of people, must necessarily, according to current laws or regulation rules, undergo a recovery and partial reuse process of their components as spare parts. Participation in auctions for these vehicles is only permitted for dismantlers listed in the waste management register in category 5 and managers of the C.E.R. code 16.01.04. At the moment, the auctions for vehicles intended for scrapping are only available in Italy.',
            },
            vies: {
                q: 'What is Vies? Why is it mandatory to be included?',
                a: 'It’s a search engine (not a database) created by the European Commission to facilitate sales between companies in UE. It’s an electronic mean of validating VAT-identification numbers of economic operators registered in the European Union for cross border transactions on goods or services. For this reason, European companies that want to be on Fleequid must necessarily be present there.',
            },
        },
        sell: {
            base: 'How to sell',
            selling: {
                q: 'How can I start selling on Fleequid?',
                a: 'If you have a bus to sell, please contact our customer service by writing to {0} or fill out the form found at {1}, providing information about the vehicle you want to sell.  You also need to register your company as a seller on the platform.',
            },

            startingPrice: {
                q: 'How is the starting price defined?',
                a: 'The starting price is the minimum price for the vehicle above which potential buyers can place their bids or make counteroffers on the Marketplace. The starting bid is calculated by Fleequid based on its technical assessment of the vehicle and must be lower than the reserve price, which is instead set by the seller.',
            },
            delivery: {
                q: 'How is the vehicle delivered?',
                a: 'After receiving payment from the buyer, the seller must enable the buyer to collect the vehicle; the buyer must promptly proceed with the pickup, which must take place within ten days from the date Fleequid informs the seller of the completion of the de-registration procedures in the country of origin of the bus. For each day of delay beyond the specified timeline, Fleequid will impose a penalty.',
            },
            howLong: {
                q: 'How long does it take for the bus to be sold?',
                a: 'When a vehicle is uploaded to the platform, Fleequid shares with the seller the start and end dates of the auction and the information about pre-auction visibility; these dates are established by Fleequid based on the specifics of the vehicle to be sold. If the sale did not conclude successfully, Fleequid would still have the right to hold up to 3 consecutive auctions within a total of 30 days.',
            },
        },
        buy: {
            base: 'How to buy',
            inspection: {
                q: 'Is it possible to inspect vehicles before bidding?',
                a: "The seller can decide whether to offer the option of live inspection of the vehicle. If allowed, the seller will define a specific day for live visits and inspections of the vehicle, directly at the seller's location.",
            },
            claims: {
                q: 'How do you handle hidden defects and complaints?',
                a: 'Fleequid acts as an intermediary between seller and buyer and, as such, is not responsible for any faults, whether hidden or visible, defects, wear and tear, irregularities, unsuitability for use, as well as errors or inaccuracies in the declarations made by the seller. By posting their auction on Fleequid, the seller commits to transparency and to providing accurate information about the condition of the vehicle; at the same time, the buyer must commit to reporting any discrepancies in a timely manner (3 days for apparent defects) within one month from the pick-up for hidden defects of the vehicle. Fleequid is available to help buyers manage complaints and provides s-pecific conditions for their handling.',
            },
            paymentMethods: {
                q: 'What payment methods are accepted?',
                a: 'Fleequid accepts bank transfers and credit card payments. More specifically, the security deposit must be paid only by credit card; the purchase of the vehicle, the payment of commissions and any additional costs must be done via bank transfer. ',
            },
            howPaymentWorks: {
                q: 'How does payment work?',
                a: 'The user who wins the auction is required to pay an amount that includes the winning price, the selling fee for Fleequid and any other agreed-upon fees (see General Conditions for more details). The buyer must pay the amount due by bank transfer no later than three days after receiving the corresponding invoice. Partial payments are not allowed.',
            },
            deposit: {
                q: 'How does the security deposit work?',
                a: 'The security deposit is the sum of money that the user gives to Fleequid when placing a bid in the auction, as a guarantee of the seriousness of the offer itself. When the auction ends all deposits are released, except those of the first and second place winners. If the winner finalizes the purchase of the vehicle, by making the bank transfer for the agreed amount (no later than 3 days after receiving the corresponding invoice), both their deposit and that of the second bidder will be released. If the winner fails to make payment within the agreed time, they will lose both the bus and the security deposit. In this case, the second-place winner will automatically become the new winner of the auction and, by purchasing the vehicle by bank transfer, the security deposit will be returned.',
            },
        },
        contactUs:
            'We hope you have found all the answers to your questions on this page, but if not, we are always happy to help. Call us on {0}, or contact us by writing to {1}.',
    },
    footer: {
        cookiePolicy: {
            base: 'Cookie policy',
            changeConsent: 'Change your consent',
            revokeConsent: 'Revoke your consent',
        },
        explore: 'Explore',
        legal: 'Legal pages',
        support: 'Do you need support?',
        vat: 'VAT Number',
        backTop: 'Back to top',
    },
    menu: {
        buy: 'Buy',
        sell: 'Sell',
        faq: 'F.A.Q.',
    },
    mktg: {
        whyFleequid: {
            title: 'Why Fleequid?',
            deal: {
                title: 'Easy deals',
                description: 'A tech platform instead of negotiations with undecided interlocutors',
            },
            processes: {
                title: 'Fast processes',
                description: 'Fleequid saves time and energy for your team',
            },
            support: {
                title: 'A transparent support',
                description: 'From registration to the delivery of the vehicle, everything is clear',
            },
        },
    },
    notifications: {
        success: 'Success',
        error: 'Error',
        genericError: 'Something went wrong. Please try again later.',
        tryAgain: 'Please try again',
        userOutBid: {
            base: 'Your offer of {prevBidAmount} for {subject} has been exceeded, the new current price is {currentBidAmount}',
            inAuction: 'this auction',
            linkedAuction: '{brand} {model} {version}',
        },
        userBailAuthorized: {
            inAuction: 'You have been authorized to participate in this auction',
            linkedAuction: 'You have been authorized to participate in the auction {brand} {model}',
        },
        userMaxBidderRestored: {
            base: 'Your offer of {prevBidAmount} for {subject} is now the highest one!',
            inAuction: 'this auction',
            linkedAuction: '{brand} {model} {version}',
        },
    },
    privacy: {
        link: 'Privacy Policy',
    },
    seo: {
        auction: {
            title: '{asset} {brand} {model} {version} {vin} at auction',
            description:
                'Buy {brand} {model} {version}, a used {asset} on Fleequid.com: join the B2B auction and place a bid for the {asset} {vin}  from brand {brand}, model {model} and version {version}.',
        },
        auctions: {
            title: 'Used {0} on sale',
            description: 'Shop online for used {0} at Fleequid.com : easily participate in B2B auctions of the best used {1} at auction.',
        },
        cookiePolicy: {
            title: 'Cookie Policy',
            description: 'Learn about cookie management on the Fleequid portal: discover how we utilize cookies to enhance your browsing experience',
        },
        homepage: {
            title: 'Buses for sale',
            description:
                'Buy or sell buses and commercial vehicles through auctions: Join Fleequid and effortlessly participate in top-notch B2B auctions for industrial vehicles',
        },
        privacyPolicy: {
            title: 'Privacy Policy',
            description: 'Privacy policy regarding the Fleequid portal: we clearly explain how we handle browsing data and all your information.',
        },
        signup: {
            title: 'Online vehicle auctions: Registration',
            description:
                'Register now on Fleequid to participate easily and conveniently in auctions for new and used industrial vehicles, buses and coaches.',
            confirm: {
                title: 'Email verification',
            },
        },
        termsAndConditions: {
            title: 'Terms and Conditions',
            description:
                'Terms and conditions of sale and purchase on Fleequid, the online portal for auctioning and purchasing industrial vehicles and buses.',
        },
        pricingSeller: {
            title: 'Pricing Seller',
            description: 'Auction fees on Fleequid.com: pricing details and specifics on the application method of fees for auctions.',
        },
        pricingBuyer: {
            title: 'Pricing Buyer',
            description: 'Auction fees on Fleequid.com: pricing details and specifics on the application method of fees for auctions.',
        },
    },
    signup: {
        link: 'Signup',
        subtitle: 'Fill in your company information and add new assets to your fleet.',
        companyRegistration: 'Register your company',
        companyInfo: 'Company information',
        companyDocuments: 'Company documents',
        contactPerson: 'Corporate contact person',
        accessCredentials: 'Access Credentials',
        fleetHandling: 'Fleet handling',
        fields: {
            address: 'Address',
            postCode: 'Postal code',
            city: 'City',
            province: 'Province/region',
            businessName: 'Business name',
            country: 'Country',
            pec: 'Certified email',
            sdi: 'SDI ID',
            vatId: 'VAT ID',
            companyRegistrationReportFile: 'Company registration report',
            firstname: 'Firstname',
            lastname: 'Lastname',
            phone: 'Telephone',
            type: {
                label: 'Operator type',
                [UserType.Dealer]: 'Dealer',
                [UserType.PeopleTransportOperator]: 'Passenger transport operator',
                [UserType.Scrapyard]: 'Scrapyard worker',
                [UserType.Other]: 'Other',
            },
            email: 'Email',
            password: 'Password',
            confirmPassword: 'Confirm password',
            stats: {
                sales: 'How many buses do you expect to sell in {year}?',
                purchase: 'How many used buses do you expect to purchase in {year}?',
                unknown: "I don't know",
            },
            consent: 'Consent',
            termsConditions: 'I have read and accept the {0}.',
            vexatiousClauses: 'I have read and accept the following clauses:',
            privacy: 'I have read and accept the {0}.',
        },
        hint: {
            phone: {
                text: 'Number format',
                rule: 'Enter the phone in the format +39 1234567890',
            },
        },
        validations: {
            requiredSDIOrPec: 'Enter at least one between SDI code or PEC address',
            companyRegistration: 'The selected file is too large. The maximum file size allowed is {size} MB',
        },
        success: {
            title: 'Thank you very much for completing the registration!',
            p1: 'Your data has been successfully received.',
            p2: 'You will shortly receive a confirmation email with a link to click to verify your email address.',
            p3: 'We invite you to also check your spam folder in your inbox, as the email may have been marked as junk, due to your configured preferences.',
            p4: 'We invite you to view the online auctions and find out if there are vehicles you are interested in. Do you want to sell your buses at the next auction? Contact us at {0}.',
            p5: 'Thank you for choosing us!',
        },
        confirm: {
            success: {
                title: 'Your email is now verified!',
                p1: 'Thank you for confirming your email {email}.',
                p2: 'Your account is now active, and you have full access to all our features.',
                p3: 'If you have any questions or need assistance, contact our support team at {0}. Welcome aboard!',
            },
            error: {
                title: 'Email verification failed',
                p1: "We're sorry, but your email verification was unsuccessful. ",
                p2: 'There may have been an issue with the confirmation link. ',
                p3: 'Please try again by clicking the verification link we sent you. If the problem continues, contact our support team for assistance at {0}.',
            },
            buyCta: 'Participate in auctions',
            hpCta: 'Back to homepage',
        },
    },
    termsConditions: {
        link: "Terms {'&'} Conditions",
    },
    login: {
        email: 'Email',
        password: 'Password',
        formSubtitle: 'Enter your Fleequid credentials to participate in the auctions',
        forgotPassword: 'Forgot password?',
        or: 'Or',
        unregistered: "Don't have a Fleequid account?",
        signupNow: 'Signup now',
    },
    passwordReset: {
        request: {
            title: 'Reset your password',
            subTitle: 'Enter your email and we will send you a message to reset your password.',
            submitButton: 'Send email',
            onSuccessTitle: 'Request accepted',
            onSuccessDescription: 'You will receive an email with the procedure to complete the password reset.',
        },
        reset: {
            title: 'Reset your password',
            description: 'Enter a new password. Once the change is confirmed, all active sessions will be invalidated.',
            newPassword: 'New password',
            confirmNewPassword: 'Confirm new password',
            submitButton: 'Change password',
            updateSuccess: 'Password changed successfully',
            tokenExpired: 'The password reset token has expired.',
            tokenInvalid: 'The password reset token is invalid.',
            startOverDescription: 'Start over the password reset process, so a new token will be generated.',
            startOverButton: 'Try again',
        },
    },
    validation: {
        msg: {
            characters: 'One or more invalid characters',
            email: "Email should resemble user{'@'}domain.ext",
            incomplete: '{field} is incomplete',
            invalid: '{field} invalid',
            invalidBid: 'The amount inserted is not valid',
            missing: '{field} is missing',
            max: 'Max length {count} character | Max length {count} characters',
            min: 'Min length {count} character | Min length {count} characters',
            length: 'The length must be exactly of {count} character | The length must be exactly of {count} characters',
            passwordWeak: 'Password too weak',
            passwordMatch: 'Passwords do not match',
            phone: 'Please use only digits, blanks and the + symbol',
            recaptcha: 'reCAPTCHA validation error',
            required: '{field} is Required',
        },
        hint: {
            password: {
                text: 'Rules for a strong password',
                rules: {
                    length: 'At least 8 characters',
                    uppercase: '1 lowercase',
                    lowercase: '1 uppercase',
                    symbol: '1 symbol',
                    number: '1 number',
                },
            },
        },
    },
    profile: {
        title: 'Your profile',
        companyData: {
            readOnlyDisclaimer: 'The company data is read-only, to request changes contact us at {0}.',
        },
        userData: {
            saveChanges: 'Confirm new data',
            cancelChanges: 'Cancel changes',
            updateSuccess: 'Personal data saved correctly',
        },
        changePassword: {
            title: 'Change password',
            description: 'Changing your password will invalidate all other active sessions except the current one.',
            currentPassword: 'Current password',
            newPassword: 'New password',
            confirmNewPassword: 'Confirm new password',
            submitButton: 'Change password',
            updateSuccess: 'Password changed successfully',
        },
        changeEmail: {
            title: 'Change email',
            description:
                'You will receive an email with a link to confirm the new email. Changing your email will invalidate all your active sessions.',
            password: 'Current password',
            email: 'Email',
            submitButton: 'Change email',
            updateSuccess: 'Email changed successfully',
        },
    },
    sessionExpired: {
        title: 'Your session is expired',
        description: 'The session has been invalidated because the credentials were changed. Please login with the new credentials.',
        close: 'Continue',
    },
};

export default en;
